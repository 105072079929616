import React, {useState} from 'react';
import './App.css';
import {ConfigProvider, theme} from 'antd';
import Chat from "./Chat";
import Login from "./Login";

function App() {
    const [token, setToken] = useState<string | null>(null);

  return (
      <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
          }}
      >
    <div className="App">
        {token ? <Chat token={token}/> : <Login updateToken={(token: string | null) => setToken(token)}/>}
    </div>
      </ConfigProvider>
  );
}

export default App;
