import React from 'react';
import './App.css';
import {ConfigProvider, GetProp, theme} from 'antd';
import { Input, Space, Typography } from 'antd';
import {MD5} from 'crypto-js';

function login(identity: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
        const url = 'https://ujep.nl/login.php';

        const data = {
            identity: MD5(identity).toString()
        };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'KEY': 'eNHL^rjaNr4W4jUlO0A@v&kvAvL5f%e@kfpC1*cwAYEhbM@PCC8VvK4nEMkfhkVufFQ6LW'
            },
            body: JSON.stringify(data)
        };

        fetch(url, options)
            .then(response => response.json())
            .then(json => {
                if (json.token) {
                    resolve(json.token);
                } else {
                    reject(new Error('Token not found'));
                }
            })
            .catch(error => {
                console.error('Chyba při volání API:', error);
                reject(error);
            });
    });
}

function Login({updateToken}: {updateToken: (token: string) => void}) {
    const onChange: GetProp<typeof Input.OTP, 'onChange'> = (text) => {
        login(text).then(token => {
            if (token) {
                updateToken(token);
            }
        });
    };

    const sharedProps = {
        onChange,
    };

    return (
            <div className="App-header">
                    <Space direction="vertical">
                        <Input.OTP variant="filled" length={8} formatter={(str) => str.toUpperCase()} {...sharedProps} />
                    </Space>
            </div>
    );
}

export default Login;
