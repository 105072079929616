import React from 'react';
import './Message.css';
import {Card} from 'antd';

type MessageProps = {
    message: string;
    type?: 'sent' | 'received';
}

function Message({message, type = 'sent'}: MessageProps) {

    return (
            <Card size="small" className={`Card ${type === 'sent' ? 'Sent' : 'Received'}`}>
                <p>{message}</p>
            </Card>

    );
}

export default Message;
