import React, {useEffect} from 'react';
import './Chat.css';
import Message from "./Message";
import {Button, Input} from 'antd';
const { TextArea } = Input;



function sendMessage(message: string, token: string): Promise<void> {
    const url = 'https://ujep.nl/message.php';
    const data = {
        message,
        token
    }
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'KEY': 'eNHL^rjaNr4W4jUlO0A@v&kvAvL5f%e@kfpC1*cwAYEhbM@PCC8VvK4nEMkfhkVufFQ6LW'
        },
        body: JSON.stringify(data)
    };

    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(response => resolve())
            .catch(error => {
                console.error('Chyba při volání API:', error);
                reject(error);
            });
    })
}

type ApiMessage = {
    message: string;
    id: number
    date?: string;
}

function codeEmoji(message: string): string {
    return message.replaceAll(/:\)/g, '😊')
        .replaceAll(/:\(/g, '😞')
        .replaceAll(/;\)/g, '😉')
        .replaceAll(/:o/g, '😮')
        .replaceAll(/:D/g, '😄')
        .replaceAll(/:P/g, '😛')
        .replaceAll(/:O/g, '😲')
        .replaceAll(/:S/g, '😖')
        .replaceAll(/:C/g, '😢')
        .replaceAll(/:X/g, '😵')
        .replaceAll(/:Z/g, '😴')
        .replaceAll(/:B/g, '😎')
        .replaceAll(/:V/g, '😇')
        .replaceAll(/:N/g, '😐')
        .replaceAll(/:L/g, '😍')
        .replaceAll(/:H/g, '😂')
        .replaceAll(/:T/g, '😭')
        .replaceAll(/:Q/g, '😡')
        .replaceAll(/:G/g, '😬')
        .replaceAll(/:K/g, '😘')
        .replaceAll(/:A/g, '😇')
        .replaceAll(/:M/g, '😷')
        //.replace(':)', '🙂')
}

function loadMessages(token: string): Promise<ApiMessage[]> {
    const url = 'https://ujep.nl/messages.php';
    const data = {
        token
    }
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'KEY': 'eNHL^rjaNr4W4jUlO0A@v&kvAvL5f%e@kfpC1*cwAYEhbM@PCC8VvK4nEMkfhkVufFQ6LW'
        },
        body: JSON.stringify(data)
    };

    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(response => response.json())
            .then(json => {
                if (json) {
                    resolve(json);
                } else {
                    reject(new Error('Token not found'));
                }
            })
            .catch(error => {
                console.error('Chyba při volání API:', error);
                resolve([]);
                //reject(error);
            });
    })
}

type Message = {
    message: string;
    type: 'sent' | 'received';
    date: Date;
}

function Chat({token}: {token: string}) {
    const [messages, setMessages] = React.useState<Message[]>([]);
    const [message, setMessage] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const ref = React.useRef<HTMLDivElement>(null);

    const normalize = (message: string = ''): string => {
        const decoder = new TextDecoder('utf-8');
        return decoder.decode(new TextEncoder().encode(message));
    }


    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMessage(e.target.value);
    };

    const scrollToBottom = () => {
        console.log('scrollToBottom');
        if (ref.current) {
            setTimeout(() => ref.current?.scrollTo({ top: ref.current.scrollHeight + 400, behavior: 'smooth' }), 500);
        }
    }

    const send = () => {
        setLoading(true);
        sendMessage(message, token)
        .finally(() => {
            setLoading(false);
            loadMessages(token).then(
                apiMessages => {
                    const scroll = apiMessages[apiMessages.length - 1].message !== messages[messages.length - 1].message;

                    setMessages(apiMessages.map((message) => (
                    {
                        type: message.id % 2 === 0 ? 'sent' : 'received',
                        message: codeEmoji(message.message),
                        date: new Date(message.date ?? 0)
                    })))
                    if (scroll) {
                        scrollToBottom();
                    }
                }
            )
        });
        setMessage('');
    }

    useEffect(() => {
        let timer: any = 0

        const getMessages = async () => {
            try {
                const apiMessages = await loadMessages(token);

                if (!Array.isArray(apiMessages) ) {
                    return [];
                }

                setMessages((prevMessages) => {
                    const newMessages: Message[] = apiMessages.map(message => ({
                        type: message.id % 2 === 0 ? 'sent' : 'received',
                        message: codeEmoji(message.message),
                        date: new Date(message.date ?? 0)
                    }));

                    if (normalize(newMessages[newMessages.length - 1]?.message) !== normalize(prevMessages[prevMessages.length - 1]?.message)) {
                        scrollToBottom();
                    }

                    return newMessages;
                });

            } catch (e) {
                console.error(e);
            }

            timer = setTimeout(getMessages, 5000);
        }

        getMessages();

        return () => {
            clearTimeout(timer);
        }
    }, [token]);

    return (
        <>
            <div className="Space" ref={ref}>
                {messages.map((message, index) => (
                    <Message message={message.message} type={message.type} key={index}/>))}

            </div>
            <div className="Form">
                <TextArea placeholder="..." allowClear autoSize={{ minRows: 3, maxRows: 3 }} onChange={onChange} value={message} className="TextArea"/>
                <Button block onClick={send} disabled={loading} className="SendButton">Send</Button>
            </div>
        </>

    );
}

export default Chat;
